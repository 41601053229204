export const API_URL = process.env.REACT_APP_API_HOST || 'http://localhost:8002';
export const CHAT_APP_ID = process.env.REACT_APP_CHAT_ID;
export const MODULE = process.env.REACT_APP_MODULE || 'enem';
export const PROJECT_TITLE = process.env.REACT_APP_PROJECT_TITLE || '';
export const PROJECT_SUBTITLE = process.env.REACT_APP_PROJECT_SUBTITLE || '';
export const VALOR_COMPETENCIA = 40;
export const TIPO_SISTEMA = process.env.REACT_APP_TIPO_SISTEMA || 'correcao';
export const RELATORIOS_URL = process.env.REACT_APP_RELATORIOS_URL || 'http://localhost:3001';
export const BASE_URL = process.env.REACT_APP_PUBLIC_URL || '';
// export const CONTATO = {
//   EMAIL: 'suporte.exames@fgv.br',
//   TELEFONE: '(21) 99753-8468',
// };
export const CONTATO = {
  EMAIL: process.env.REACT_APP_EMAIL || 'suporte.enemredacoes@fgv.br',
  TELEFONE: process.env.REACT_APP_TELEFONE || '0800 591 3078',
};

export const REACT_APP_TITLE = process.env.REACT_APP_TITLE || 'Correção';
export const URL_INSCRICOES = process.env.REACT_APP_LINK_INSCRICOES || 'https://redacoes.enem.fgv.br/inscricoes';

export const MAX_COTA_AVALIADOR = process.env.REACT_APP_MAX_COTA_AVALIADOR || 200;
export const MAX_COTA_SUPERVISOR = process.env.REACT_APP_MAX_COTA_SUPERVISOR || 300;

const CORRECAO = {
  COMPETENCIA1: [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
  ],

  COMPETENCIA2: [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
  ],

  COMPETENCIA3: [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
  ],

  COMPETENCIA4: [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
  ],

  COMPETENCIA5: [
    { value: -1, label: 'DDH' },
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
  ],

  TIPO_PROBLEMA_PEDAGOGICO_EM: [
    { value: 1, label: 'COMPETÊNCIA I' },
    { value: 2, label: 'COMPETÊNCIA II' },
    { value: 3, label: 'COMPETÊNCIA III' },
    { value: 4, label: 'COMPETÊNCIA IV' },
    { value: 5, label: 'COMPETÊNCIA V' },
  ],

  TIPO_PROBLEMA_PEDAGOGICO_EF: [
    { value: 1, label: 'COMPETÊNCIA I' },
    { value: 2, label: 'COMPETÊNCIA II' },
    { value: 3, label: 'COMPETÊNCIA III' },
    { value: 4, label: 'COMPETÊNCIA IV' },
  ],

  TIPO_CORRECAO: {
    PRIMEIRA: 1,
    SEGUNDA: 2,
    TERCEIRA: 3,
    QUARTA: 4,
    OURO: 5,
    MODA: 6,
    AUDITORIA: 7,
  },
};

CORRECAO.SITUACOES = [
  { value: 12, label: '99 - Resposta em branco.' },
  { value: 10, label: '98 - Não administrado/erro de material.' },
  { value: 11, label: '97 - Nulo ou não se aplica.' },
];
CORRECAO.SITUACOES_ABREVIACAO = {
  2: 'FEA',
  3: 'C',
  6: 'FT',
  7: 'NATT',
  9: 'PD',
};
CORRECAO.TIPO_PROBLEMA_IMAGEM = [
  { value: 9, label: 'PROVA EM BRANCO' },
  { value: 6, label: 'IMAGEM NÃO ENCONTRADA' },
  { value: 7, label: 'IMAGEM CORTADA (FOLHA DOBRADA)' },
  { value: 11, label: 'DIGITALIZAÇÃO MUITO CLARA/COM FALHAS' },
  { value: 8, label: 'OUTRAS DÚVIDAS' },
  { value: 12, label: 'CONFIRMAR INDÍCIO DE IDENTIFICAÇÃO' },
];

export { CORRECAO };

export const OCORRENCIA = {
  CATEGORIA: {
    DUVIDA_PEDAGOGICA: 1,
    PROBLEMA_IMAGEM: 2,
  },
  STATUS: {
    EM_ABERTO: 1,
    RESPONDIDA: 2,
    RESOLVIDA: 3,
    FECHADA: 4,
    ENCAMINHADA: 5,
    REESCANEAMENTO_SOLICITADO: 6,
    REESCANEAMENTO_RESPONDIDO: 7,
  },
  TABLE_OCORRENCIA_AVALIADOR: [
    { Header: 'Código imagem', accessor: 'imagem_id' },
    { Header: 'Código ocorrência', accessor: 'id' },
    { Header: 'Data abertura', accessor: 'data_solicitacao' },
    { Header: 'Classificação', accessor: 'categoria_descricao' },
    { Header: 'Referência', accessor: 'tipo_descricao' },
    { Header: 'Responsável', accessor: 'nome_responsavel' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Ver', accessor: 'ver' },
  ],
  TABLE_OCORRENCIA_SUPERVISOR: [
    { Header: 'Código Imagem', accessor: 'id_imagem' },
    { Header: 'Código Ocorrência', accessor: 'id' },
    { Header: 'Nome', accessor: 'perfil_nome' },
    { Header: 'Data abertura', accessor: 'data_solicitacao' },
    { Header: 'Time', accessor: 'perfil_time' },
    { Header: 'Ocorrência', accessor: 'categoria_descricao' },
    { Header: 'Referência', accessor: 'tipo_descricao' },
    { Header: 'Tipo', accessor: 'perfil_tipo' },
    { Header: 'Responsável', accessor: 'responsavel' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Ver', accessor: 'ver' },
  ],
};

export const AVALIADORES = {
  TABLE_AVALIADORES_LIST: [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Nome', accessor: 'nome' },
    { Header: 'CPF', accessor: 'cpf' },
    { Header: 'E-mail', accessor: 'email' },
    { Header: 'Telefone', accessor: 'telefone' },
    { Header: 'Local', accessor: 'local' },
    { Header: 'Perfil', accessor: 'perfil' },
    { Header: 'Correções', accessor: 'correcoes' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Desempenho', accessor: 'desempenho' },
    { Header: 'Ver', accessor: 'ver' },
  ],
};

export const StatusCorrecao = {
  FINALIZADA: 3,
  OCORRENCIA: 4,
};


export const TIPO_REDACAO = {
  0: 'Total',
  1: '1ª',
  2: '2ª',
  3: '3ª',
  4: '4ª',
  7: 'Auditoria',
};


export const HIERARQUIA = {
  COORDENACAO: 1,
  FGV: 2,
  POLO: 3,
  TIME: 4,
};

export const TIPO_REDACAO_DESEMPENHO = [
  { value: 2, label: 'Ouro' },
  { value: 3, label: 'Moda' },
];

export const StatusOcorrencia = [
  { value: 1, label: 'Em Aberto', permission: '' },
  { value: 2, label: 'Respondida', permission: '' },
  { value: 5, label: 'Encaminhada', permission: '' },
  { value: 13, label: 'Enc. Respondido', permission: 'show_status_forwarding_answered' },
  { value: 6, label: 'Reescaneamento Solicitado', permission: '' },
  { value: 7, label: 'Reescaneamento Respondido', permission: '' },
  { value: 8, label: 'Encerrada', permission: '' },
  { value: 12, label: 'Transferida para o time técnico', permission: '' },
];

export const MAX_CORRECOES_DIA = [
  '',
  0,
  25,
  50,
  75,
  100,
  125,
  150,
  175,
  200,
];

const mapCota = d => ({ value: 25 + (d * 25), label: 25 + (d * 25) });
export const COTA_AVALIADOR_OPTIONS = [...Array((MAX_COTA_AVALIADOR / 25)).keys()].map(mapCota);
export const COTA_SUPERVISOR_OPTIONS = [...Array((MAX_COTA_SUPERVISOR / 25)).keys()].map(mapCota);
